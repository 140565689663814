import React from "react";
import "./App.css";
import image from "./sorry_we_are_closed.png";
import Background from "./Background";

const App = () => {
  return (
    <>
      <Background />

      <div className="info">
        Hello Friends, it comes with a heavy heart, as of February 2020,
        Driveway 327 is officially closed. Thank you to all who have supported
        previous events and exhibitions. Some good things come to end. A new
        project will emerge from the spirit of Driveway 327. If you are
        interested in learning more visit:{" "}
        <a href="https://www.troykreiner.com/project/curatorial">
          https://www.troykreiner.com/project/curatorial
        </a>{" "}
        for the latest or{" "}
        <a href="http://eepurl.com/gf8Oi1">signup for my newsletter</a> for
        announcements.
      </div>

      <img className="sign" src={image} alt="Sorry we're closed" />
    </>
  );
};

export default App;
