import React, { useState, useEffect } from "react";

import one from "./backgrounds/1.jpeg";
import two from "./backgrounds/2.jpeg";
import four from "./backgrounds/4.jpeg";
import five from "./backgrounds/5.jpeg";
import six from "./backgrounds/6.jpeg";
import seven from "./backgrounds/7.jpeg";
import eight from "./backgrounds/8.jpeg";

const imgs = [one, two, four, five, six, seven, eight];

const Background = () => {
  const [index, setIndex] = useState(0);

  useEffect(
    () =>
      // Preload images
      imgs.forEach(x => {
        const img = new Image();
        img.src = x;
      }),
    []
  );

  useEffect(() => {
    setTimeout(
      () => setIndex(index + 1 <= imgs.length - 1 ? index + 1 : 0),
      3000
    );
  });

  return (
    <div
      className="background"
      style={{ backgroundImage: `url(${imgs[index]})` }}
    />
  );
};

export default Background;
